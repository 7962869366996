import React, { memo, useMemo } from 'react';
import { Spin } from 'antd';
import { SpinProps } from 'antd/lib/spin';
import SpinDarkIcon from 'src/assets/images/icons/spin_dark.svg';
import SpinLightIcon from 'src/assets/images/icons/spin_light.svg';
import styles from './index.module.less';

interface CustomSpinProps extends SpinProps {
  children?: React.ReactNode;
}


const CustomSpin: React.FC<CustomSpinProps> = ({ children, ...rest }) => {

  const icon = useMemo(() => (
    <div className={styles.custom_spin_icon}>
      <SpinLightIcon className={styles.custom_spin_light} />
      <SpinDarkIcon className={styles.custom_spin_dark} />
    </div>
  ), []);

  return <Spin indicator={icon} {...rest}>{children}</Spin>;
};

export default memo(CustomSpin);
